import Bear from '@/assets/Images/bear-rest.png'
import Invite from '@/assets/svg/invite-icon.svg'
import { Tabs } from '@/libs/types'
import type { TaskInfo } from '@/libs/types/task'
import { generateImageByTask } from '@/libs/utils/task/task'
import { handleReactGASend } from '@/libs/utils/tracking'
import {
  claimTask,
  completeTask,
  getTaskList,
} from '@/libs/utils/wallet/action'
import { bottomTabStore } from '@/store/BottomTabStore'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { toast } from 'sonner'
import { Card } from '../components/TabTask/Card'
import { SideBar } from '../components/TabTask/SideBar'
import { StandardTask } from '../components/TabTask/StandardTask'

const typeSearch = {
  Standard: 0,
  Partner: 1,
}
export type TaskStatus = 'SUCCESS' | 'CLAIM' | 'GO'

const getTaskStatus = (
  userTask: TaskInfo['user_task'],
  frequencyType: TaskInfo['task']['frequency_type'],
): TaskStatus => {
  if (!userTask) return 'GO'
  if (userTask.claim_at && userTask.done_at && frequencyType === 'always') {
    return 'GO'
  }
  if (userTask.claim_at) return 'SUCCESS'
  if (userTask.done_at) return 'CLAIM'

  return 'GO'
}

export function TabTask() {
  const [tasks, setTask] = useState<TaskInfo[]>([])
  const [type, setType] = useState<number>(typeSearch.Standard)

  const queryClient = useQueryClient()

  const handleChangeType = (value: number) => {
    setType(value)
  }

  const { data: task } = useQuery({
    queryKey: ['task'],
    queryFn: () => getTaskList(),
    refetchOnWindowFocus: true,
  })

  const completeMutation = useMutation({
    mutationFn: completeTask,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['task'] })
    },
    onError: (error) => {
      toast.error(`Unable to complete task: ${error.message}`)
    },
  })

  const claimMutation = useMutation({
    mutationFn: claimTask,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['task'] })
      queryClient.invalidateQueries({ queryKey: ['check-game'] })
    },
    onError: (error) => {
      toast.error(`Unable to claim reward: ${error.message}`)
    },
  })

  useEffect(() => {
    if (task) {
      setTask([])
      const updatedTasks = task.map((element) => ({
        ...element,
        task: {
          ...element.task,
          statusTask: getTaskStatus(
            element.user_task,
            element.task.frequency_type,
          ),
        },
      }))
      setTask(updatedTasks)
    }
  }, [task])

  const handleChangeTask = async (id: number) => {
    completeMutation.mutate(id)
  }

  const handleClaimTask = async (id: number) => {
    claimMutation.mutate({ id })
  }

  useEffect(() => {
    handleReactGASend('TasksScreenView')
  }, [])

  useEffect(() => {
    if (type === typeSearch.Partner) {
      handleReactGASend('PartnerTaskScreenView')
    } else {
      handleReactGASend('InHouseTaskScreenView')
    }
  }, [type])

  const countTaskForPartner = useMemo(() => {
    const result = {
      partner: 0,
      standard: 0,
    }
    if (task) {
      task.map((element) => {
        if (element.task.task_type === 'partner') {
          result.partner = result.partner + 1
        }
        if (element.task.task_type === 'system') {
          result.standard = result.standard + 1
        }
      })
    }
    return result
  }, [task])

  const showNoneTask = () => {
    return (
      <div className="w-full h-[calc(100vh-380px)] flex items-center justify-center flex-col">
        <img src={Bear} className="w-[200px] h-[200px]" alt="bear" />
        <p className="text-[14px] text-[#FFFFFF] leading-[24px] font-[500]">
          There are no missions yet.
        </p>
        <p className="text-[14px] text-[#FFFFFF] leading-[24px] font-[500]">
          Stay tuned for more coming soon.
        </p>
      </div>
    )
  }

  const handleChangeTab = (tab: Tabs) => {
    bottomTabStore.send({
      type: 'setCurrentTab',
      tab,
    })
  }

  return (
    <div className="pt-[10px] pl-[20px] pr-[20px]">
      <div
        onClick={() => handleChangeTab(Tabs.Invite)}
        onKeyDown={() => {}}
        className="fixed right-[0px] top-[175px] bg-[#8C6F00] rounded-l-[24px] h-[45px] min-w-[80px] flex items-center justify-center gap-[5px]"
      >
        <img src={Invite} alt="invite" className="w-[20px] h-[20px]" />
        <p className="text-[#ffffff] text-[14px] font-bold">Invite</p>
      </div>
      <div className="flex items-center justify-center pt-[10px] pb-[10px] gap-[5px]">
        <p className="text-[24px] text-[#ffffff] font-['DM Sans'] font-[600]">
          Let's get more
        </p>
        <p className="text-[24px] text-[#FFDE5E] font-['DM Sans'] font-[600]">
          $BECA.
        </p>
      </div>
      <div className="pb-[10px]">
        <SideBar tab={type} handleChangeTab={handleChangeType} />
      </div>
      {type === typeSearch.Standard &&
        task &&
        countTaskForPartner.standard > 0 && (
          <StandardTask
            tasks={tasks}
            handleChangeTask={handleChangeTask}
            handleClaimTask={handleClaimTask}
          />
        )}
      <p className="text-[#52A7E7] text-[18px] font-bold mb-[10px] font-['DM Sans'] h-[30px]" />
      {type === typeSearch.Partner && countTaskForPartner.partner > 0 && (
        <div className="max-h-[calc(100vh-280px)] min-h-[calc(100vh-320px)] overflow-auto border-[1.5px] border-[#3892CF] rounded-3xl bg-[#52A7E73D] flex flex-col gap-[10px] p-[10px] pb-[20px]">
          {tasks.map((element, index: number) => {
            return (
              element.task.task_type === 'partner' && (
                <Card
                  key={element.task.id}
                  avatar={
                    element?.task?.image_url
                      ? element?.task?.image_url
                      : generateImageByTask(element)
                  }
                  name={element.task.title}
                  value={`+${element.task.reward_point.toLocaleString('en-US')}`}
                  unit="$BECA"
                  status={
                    element.task.statusTask as
                      | 'GO'
                      | 'CLAIM'
                      | 'LOADING'
                      | 'SUCCESS'
                  }
                  handleChangeTask={handleChangeTask}
                  index={index}
                  task={element}
                  id={element.task.id}
                  handleClaimTask={handleClaimTask}
                />
              )
            )
          })}
        </div>
      )}

      {type === typeSearch.Standard &&
        task &&
        countTaskForPartner.standard === 0 &&
        showNoneTask()}
      {type === typeSearch.Partner &&
        countTaskForPartner.partner === 0 &&
        showNoneTask()}
    </div>
  )
}
