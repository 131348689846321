import { WalletButton } from '@/components/Button'
import { Container, Content } from '@/components/Layout'
import { useMutationConnectDiscord } from '@/libs/hooks'
import { useKeyboardVisibility } from '@/libs/hooks/useKeyboardVisibility'
import {} from '@/libs/utils/wallet/action'
import { walletStore } from '@/store/WalletStore'
import { useQueryClient } from '@tanstack/react-query'
import { useSelector } from '@xstate/store/react'
import { useState } from 'react'
import { Input, Text } from 'react-aria-components'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

const ConnectDiscord: React.FC = () => {
  useKeyboardVisibility()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { activeAccount } = useSelector(walletStore, (state) => state.context)
  const { mutateAsync: connectDiscord, isPending: isConnectingDiscord } =
    useMutationConnectDiscord()
  const [discordUsername, setDiscordUsername] = useState<string | undefined>(
    undefined,
  )

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscordUsername(e.target.value)
  }

  const handleConnectDiscord = async () => {
    await connectDiscord(
      {
        discord_username: discordUsername as string,
        wallet_address: activeAccount?.address as `0x${string}`,
      },
      {
        onSuccess: async () => {
          queryClient.refetchQueries({
            queryKey: ['connect-discord', activeAccount?.address],
          })

          navigate('/account-setting', {
            replace: true,
          })
        },
        onError: () => {
          toast.error(`Can't find username, please recheck`)
        },
      },
    )
  }

  return (
    <Container>
      <Content className="flex flex-1 flex-col justify-between py-5">
        <div>
          <div className="flex flex-col mb-2">
            <Text className="text-white text-xl font-semibold">
              Enter your Discord Username
            </Text>
            <Text className="text-white opacity-64 text-sm font-normal mt-1">
              Please keep in mind that this is irreversible, enter correct
              format of your Discord username. For example: “hmt.bluecc”
            </Text>
          </div>
          <div className="flex items-center justify-center">
            <Input
              autoFocus
              className="w-full bg-transparent border-[1px] focus:border-yellow px-4 py-3 rounded-xl text-white placeholder-white placeholder-opacity-64"
              placeholder="Enter user name discord"
              onChange={handleChangeInput}
              value={discordUsername}
            />
          </div>
        </div>
        {isConnectingDiscord ? (
          <WalletButton className="mb-12 max-h-12" onPress={() => {}}>
            Connecting...
          </WalletButton>
        ) : (
          <WalletButton
            className="mb-12 max-h-12"
            onPress={handleConnectDiscord}
            isDisabled={!discordUsername}
          >
            Continue
          </WalletButton>
        )}
      </Content>
    </Container>
  )
}

export { ConnectDiscord }
