import { useQuery } from '@tanstack/react-query'
import request from '../config/request'
import type { RankingWallet } from '../types'

async function checkRankWallet(walletAddress: string) {
  const res = await request.get<RankingWallet>(
    `/public/ranking/${walletAddress}`,
  )
  return res.data
}

export function useCheckRankWallet(walletAddress: string) {
  const { data, ...res } = useQuery({
    queryKey: ['check-rank-wallet', walletAddress],
    queryFn: () => checkRankWallet(walletAddress),
    enabled: !!walletAddress,
  })

  return { rankWallet: data ?? null, ...res }
}
