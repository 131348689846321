import { useMutation } from '@tanstack/react-query'
import request from '../config/request'
import type { IConnectDiscord } from '../types'

async function connectDiscord(data: IConnectDiscord) {
  const res = await request.post('/discord/connect', data)
  return res
}

export const useMutationConnectDiscord = () => {
  return useMutation({
    mutationFn: (data: IConnectDiscord) => connectDiscord(data),
  })
}
