interface Props {
  tab: number
  handleChangeTab: (value: number) => void
}

export function SideBar(props: Props) {
  const SidebarOption = ['Standard', 'Partner']
  return (
    <div className="w-full rounded-[24px] h-[44px] bg-[#0C1E2B] flex p-[2px]">
      {SidebarOption.map((element: string, index: number) => {
        return (
          <div
            key={element}
            onClick={() => props.handleChangeTab(index)}
            onKeyDown={() => {}}
            className={`rounded-3xl flex flex-1 justify-center items-center ${index === props.tab ? 'bg-[#52A7E7]' : 'bg-transparent'}`}
          >
            <p className="text-sm font-bold text-[#ffffff] uppercase">
              {element}
            </p>
          </div>
        )
      })}
    </div>
  )
}
