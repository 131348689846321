import { useQuery } from '@tanstack/react-query'
import request from '../config/request'
import type { Ranking } from '../types'

export const getRank = async () => {
  const res = await request.get<Ranking>('/bera-bonds/ranking')
  return res.data as Ranking
}

export const useRanking = () => {
  const { data } = useQuery({
    queryKey: ['get-ranking'],
    queryFn: () => getRank(),
  })

  return data ?? {} as Ranking
}
