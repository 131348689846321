import ArrowRightSvg from '@/assets/svg/arrow-right.svg'
import type React from 'react'
import { Button, Text } from 'react-aria-components'
import { twMerge } from 'tailwind-merge'

type Props = {
  title: string | React.ReactNode
  icon: string
  onClick: () => void
  titleClassName?: string
}

const SettingItem: React.FC<Props> = ({
  title,
  icon,
  onClick,
  titleClassName,
}) => {
  return (
    <Button
      className="flex items-center justify-between bg-transparent rounded-lg pressed:bg-opacity-90"
      onPress={onClick}
    >
      <div className="flex items-center">
        <div className="p-2 bg-[#4CA4E6] rounded-lg">
          <img src={icon} className="w-[24px] h-[24px]" alt="icon" />
        </div>
        <Text
          className={twMerge('ml-3 font-semibold text-[14px]', titleClassName)}
        >
          {title}
        </Text>
      </div>
      <img src={ArrowRightSvg} className="w-4 h-4" alt="Arrow" />
    </Button>
  )
}

export { SettingItem }
