import TickIcon from '@/assets/Images/tick-icon.png'
import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import { WalletButton } from '@/components/Button/WalletButton'
import { BottomSheetModal } from '@/components/Modal/BottomSheetModal'
import { useMyMemeReachThreshold } from '@/libs/hooks'
import { Tabs } from '@/libs/types'
import type { TaskInfo } from '@/libs/types/task'
import { generateImageByTask } from '@/libs/utils/task/task'
import { claimTask as claimTaskAction } from '@/libs/utils/wallet/action'
import { bottomTabStore } from '@/store/BottomTabStore'
import { transactionStore } from '@/store/TransactionStore'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useInitData, useUtils } from '@telegram-apps/sdk-react'
import { useSelector } from '@xstate/store/react'
import { useState } from 'react'
import { toast } from 'sonner'
import { TaskFaucet } from './TaskFaucet'
import { TaskMintNFT } from './TaskMintNFT'
import { TaskSend } from './TaskSend'
import { TaskSwap } from './TaskSwap'

interface Props {
  avatar: string
  name: string
  value: string
  unit: string
  status: 'GO' | 'CLAIM' | 'LOADING' | 'SUCCESS'
  handleChangeTask: (id: number) => void
  handleClaimTask: (id: number) => void
  index: number
  task: TaskInfo
  id: number
}

export function Card(props: Props) {
  const utils = useUtils()
  const queryClient = useQueryClient()
  const initData = useInitData()
  const { txsBeraPump } = useSelector(
    transactionStore,
    (state) => state.context,
  )
  const { txsCreateMeme } = useSelector(
    transactionStore,
    (state) => state.context,
  )

  const { tokens: memeTokensReachThreshold } = useMyMemeReachThreshold()
  const [open, setOpen] = useState(false)
  const [openSendDialog, setOpenSendDialog] = useState(false)
  const [openFaucetDialog, setOpenFaucetDialog] = useState(false)
  const [openMintDialog, setOpenMintDialog] = useState(false)
  const [openSwapDialog, setOpenSwapDialog] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)

  const claimMutation = useMutation({
    mutationFn: claimTaskAction,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['task', 'my-meme-reach-threshold'],
      })
    },
    onError: (error) => {
      toast.error(`Unable to claim reward: ${error.message}`)
    },
  })

  const processTask = (task: TaskInfo) => {
    switch (task.task.action_type) {
      case 'telegram_boost':
        break
      case 'telegram_display_name':
        if (checkTelegramDisplayName()) {
          props.handleChangeTask(task.task.id)
        }
        break
      case 'onchain_faucet':
        setOpenFaucetDialog(true)
        break
      case 'onchain_send_token':
        setOpenSendDialog(true)
        break
      case 'onchain_mint_nft':
        setOpenMintDialog(true)
        break
      case 'onchain_swap_token':
        setOpenSwapDialog(true)
        break
      case 'onchain_add_liquidity':
        setOpenSwapDialog(true)
        break
      case 'onchain_create_meme':
        if (txsCreateMeme.length > 0) {
          props.handleChangeTask(task.task.id)
        } else {
          bottomTabStore.send({
            type: 'setCurrentTab',
            tab: Tabs.CreateMeme,
          })
        }
        break
      case 'onchain_meme_threshold':
        if (memeTokensReachThreshold?.length > 0) {
          props.handleChangeTask(task.task.id)
        } else {
          bottomTabStore.send({
            type: 'setCurrentTab',
            tab: Tabs.CreateMeme,
          })
        }
        break
      case 'onchain_bera_pump':
        if (txsBeraPump.length >= Number(task.task.minimum_requirement)) {
          props.handleChangeTask(task.task.id)
        } else {
          bottomTabStore.send({
            type: 'setCurrentTab',
            tab: Tabs.Meme,
          })
        }
        break
      default:
        if (task.task.task_url) {
          utils.openLink(task.task.task_url)
        }
        props.handleChangeTask(task.task.id)
        break
    }
  }

  const checkTelegramDisplayName = () => {
    const last_name = initData?.user?.lastName ?? ''
    const first_name = initData?.user?.firstName ?? ''
    if (last_name.includes('$BEAR') || first_name.includes('$BEAR')) {
      return true
    }

    toast.error('Claim false!  Please add $BEAR in your name!')
    return false
  }

  const claimTask = async (id: number) => {
    setLoading(true)
    await new Promise((resolve) => setTimeout(resolve, 2000))
    if (props.task.task.action_type === 'onchain_meme_threshold') {
      claimMutation.mutate({
        id,
        claimByValue: memeTokensReachThreshold?.[0]?.address,
      })
    } else {
      await props.handleClaimTask(id)
    }
    if (props.task.task.action_type === 'onchain_bera_pump') {
      transactionStore.send({
        type: 'claimBeraPumpTransaction',
      })
    }
    if (props.task.task.action_type === 'onchain_create_meme') {
      transactionStore.send({
        type: 'clearCreateMemeTransaction',
      })
    }
    toast.success(`You have successfully claimed ${props.value} $BECA`)
    setOpen(false)
    setLoading(false)
  }

  const buttonGo = (task: TaskInfo) => {
    return (
      <WalletButton
        className="max-w-[50px] h-[32px]"
        onPress={() => processTask(task)}
      >
        <p className="text-[12px] text-[#FFFFFF] leading-[16px] font-[400]">
          Go
        </p>
      </WalletButton>
    )
  }

  const buttonClaim = () => {
    return (
      <WalletButton
        className="max-w-[50px] h-[32px]"
        color="blue-800"
        onPress={() => setOpen(true)}
      >
        <p className="text-[12px] text-[#FFFFFF] leading-[16px] font-[400]">
          Claim
        </p>
      </WalletButton>
    )
  }

  const buttonLoading = () => {
    return (
      <WalletButton className="w-full h-[48px] border-0" color="blue-800">
        <svg
          aria-hidden="true"
          className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </WalletButton>
    )
  }

  const buttonSuccess = () => {
    return (
      <WalletButton
        className="max-w-[50px] h-[32px] border-0 min-w-[36px]"
        color="not-shadow"
      >
        <img src={TickIcon} className="h-[20px] w-[20px]" alt="copy icon" />
      </WalletButton>
    )
  }

  return (
    <div className="w-full flex items-center justify-center p-[2px] border-b-[1px] border-b-[#52A7E733] gap-[10px] pb-[10px]">
      <img
        src={props.avatar}
        className="w-[40px] h-[40px] rounded-[50%]"
        alt="avatar"
      />
      <div className="flex items-center justify-between w-full">
        <div className="flex flex-col">
          <p className="text-[14px] text-[#ffffff] leading-[24px] font-semibold">
            {props.name}{' '}
            {props.task.task.action_type === 'onchain_bera_pump' &&
              `(${txsBeraPump?.length}/${props.task.task.minimum_requirement})`}
          </p>
          <div className="flex gap-[5px]">
            <p className="text-[12px] text-[#FFDE5E] leading-[16px] font-semibold">
              {props.value}
            </p>
            <img
              src={BeraCoinImg}
              width="16px"
              height="16px"
              alt="token icon"
            />
            <p className="text-[12px] text-[#FFFFFF] leading-[16px] font-[400]">
              {props.unit}
            </p>
          </div>
        </div>
        <div>
          {props.status === 'GO' && buttonGo(props.task)}
          {props.status === 'CLAIM' && buttonClaim()}
          {props.status === 'LOADING' && buttonLoading()}
          {props.status === 'SUCCESS' && buttonSuccess()}
        </div>
      </div>
      <BottomSheetModal
        open={open}
        title="Claim reward"
        onClose={() => setOpen(false)}
        heightPercent={0.6}
      >
        <div className="flex flex-col px-4 py-5 gap-3 w-full items-center justify-center pt-[30px]">
          <img
            src={
              props?.task?.task?.image_url
                ? props.task.task.image_url
                : generateImageByTask(props.task)
            }
            alt="telegram icon"
            className="w-[64px] h-[64px]"
          />
          <p className="text-[20px] text-[#FFFFFF] leading-[28px] font-[600] text-center">
            {props.task.task.title}
          </p>
          <p className="text-[14px] text-[#FFFFFF] leading-[24px] font-[400] opacity-[0.64] text-center max-w-[100%]">
            {props.task.task.description}
          </p>
          <div className="flex items-center justify-center gap-[5px] pb-[10px]">
            <img
              src={BeraCoinImg}
              width="24px"
              height="24px"
              alt="token icon"
            />
            <p className="text-[20px] text-[#FFDE5E] leading-[24px] font-[700]">
              {`${props.value}`}
            </p>
            <p className="text-[20px] text-[#FFFFFF] leading-[24px] font-[700]">
              $BECA
            </p>
          </div>
          {!loading && (
            <WalletButton
              className="w-full h-[48px]"
              onPress={() => claimTask(props.id)}
            >
              <p className="text-[16px] text-[#FFFFFF] leading-[24px] font-[700]">
                Check
              </p>
            </WalletButton>
          )}
          {loading && <div className="w-full">{buttonLoading()}</div>}
        </div>
      </BottomSheetModal>
      {TaskFaucet(
        openFaucetDialog,
        setOpenFaucetDialog,
        'Task',
        props.task,
        props.handleChangeTask,
        loading,
        claimTask,
      )}
      {TaskSend(
        openSendDialog,
        setOpenSendDialog,
        'Task',
        props.task,
        props.handleChangeTask,
        loading,
        claimTask,
      )}
      {TaskMintNFT(
        openMintDialog,
        setOpenMintDialog,
        'Tasks',
        props.task,
        props.handleChangeTask,
        loading,
        claimTask,
      )}
      <TaskSwap
        open={openSwapDialog}
        setOpen={setOpenSwapDialog}
        task={props.task}
      />
    </div>
  )
}
