import { useQuery } from '@tanstack/react-query'
import request from '../config/request'
import type { IConnectDiscordInfo } from '../types'

export const getConnectDiscordInfo = async (walletAddress: string) => {
  const res = await request.get<IConnectDiscordInfo>(
    `/discord?wallet_address=${walletAddress}`,
  )
  return res.data
}

export const useConnectDiscord = (walletAddress: string) => {
  const { data } = useQuery({
    queryKey: ['connect-discord', walletAddress],
    queryFn: () => getConnectDiscordInfo(walletAddress),
  })

  return data ?? null
}
