import BearTabIcon from '@/assets/Images/bear-tab.png'
import HomeFocusedSvg from '@/assets/svg/home-focused.svg?react'
import InviteFocusedSvg from '@/assets/svg/invite-focused.svg'
import InviteSvg from '@/assets/svg/invite.svg'
import TaskFocusedSvg from '@/assets/svg/task-focused.svg?react'
import { AccountHeader, Container } from '@/components/Layout'
import { useConnectSocket } from '@/libs/hooks'
import { Tabs } from '@/libs/types'
import { handleReactGASendEvent } from '@/libs/utils/tracking'
import { TabGame } from '@/pages/Game'
import { bottomTabStore } from '@/store/BottomTabStore'
import { Tabbar } from '@telegram-apps/telegram-ui'
import { useSelector } from '@xstate/store/react'
import type React from 'react'
import { CreateMeme } from '../components/TabMeme/CreateMeme'
import { TabDApps } from './TabDApps'
import { TabInvite } from './TabInvite'
import { TabLeaderBoad } from './TabLeaderBoad'
import { TabMeme } from './TabMeme'
import { TabTask } from './TabTask'
import { TabWallet } from './TabWallet'

// type checkInData = {
//   continuous_days: number
//   last_check_in_at: string
// }

const eventNames: Record<Tabs, string> = {
  wallet: 'HomeTabClick',
  leaderboard: 'LeaderBoardTabClick',
  game: 'GameTabClick',
  invite: 'InviteTabClick',
  tasks: 'TasksTabClick',
  dapps: 'DAppsTabClick',
  meme: 'MemeTabClick',
  createMeme: 'CreateMemeTabClick',
}

const tabs = [
  {
    id: Tabs.Wallet,
    text: 'Home',
    // Icon: ({
    //   isFocused,
    // }: {
    //   isFocused: boolean
    // }) => (
    //   <img
    //     src={isFocused ? HomeFocusedSvg : HomeSvg}
    //     alt="game"
    //     className="w-6 h-6"
    //   />
    // ),
    Icon: ({
      isFocused,
    }: {
      isFocused: boolean
    }) => (
      <HomeFocusedSvg
        viewBox="0 0 25 24"
        className={isFocused ? 'fill-[#003F60]' : 'text-white'}
      />
    ),
  },
  // {
  //   id: Tabs.DApps,
  //   text: 'DApps',
  //   Icon: ({
  //     isFocused,
  //   }: {
  //     isFocused: boolean
  //   }) => (
  //     <img
  //       src={isFocused ? DAppFocusedSvg : DappsSvg}
  //       alt="game"
  //       className="w-6 h-6"
  //     />
  //   ),
  // },
  {
    id: Tabs.Meme,
    text: 'Meme',
    Icon: ({
      isFocused,
    }: {
      isFocused: boolean
    }) => <img src={isFocused ? InviteFocusedSvg : InviteSvg} alt="game" />,
  },
  // {
  //   id: Tabs.Game,
  //   text: 'Game',
  //   Icon: ({
  //     isFocused,
  //   }: {
  //     isFocused: boolean
  //   }) => (
  //     <img
  //       src={isFocused ? BearFocusedSvg : BearSvg}
  //       alt="game"
  //       className="w-6 h-6"
  //     />
  //   ),
  // },
  {
    id: Tabs.Tasks,
    text: 'Tasks',
    Icon: ({
      isFocused,
    }: {
      isFocused: boolean
    }) => (
      <TaskFocusedSvg
        viewBox="0 0 21 18"
        width={24}
        height={24}
        className={isFocused ? 'fill-[#003F60]' : 'text-white'}
      />
    ),
  },
]

const BottomTab: React.FC = () => {
  const cs = useConnectSocket()

  // const {
  //   data: checkInInfo,
  //   isLoading,
  //   refetch,
  // } = useQuery({
  //   queryKey: ['daily check in info'],
  //   queryFn: () => getCheckInInfo(),
  //   refetchOnWindowFocus: true,
  // })
  const { currentTab } = useSelector(bottomTabStore, (state) => state.context)

  const renderContent = () => {
    switch (currentTab) {
      case Tabs.Wallet:
        return <TabWallet />
      case Tabs.Game:
        return <TabGame {...cs} handleChangeTab={handleChangeTab} />
      case Tabs.Tasks:
        return <TabTask />
      case Tabs.Leaderboard:
        return <TabLeaderBoad />
      case Tabs.Invite:
        return <TabInvite />
      case Tabs.DApps:
        return <TabDApps />
      case Tabs.Meme:
        return <TabMeme />
      case Tabs.CreateMeme:
        return <CreateMeme />

      default:
        return <TabWallet />
    }
  }

  const handleChangeTab = (tab: Tabs) => {
    handleReactGASendEvent(eventNames[tab])

    bottomTabStore.send({
      type: 'setCurrentTab',
      tab,
    })
  }

  // const checked = useMemo(() => {
  //   let result = true
  //   if (!isLoading && activeAccount?.address) {
  //     if (checkInInfo?.last_check_in_at) {
  //       const lastTimeCheckIn = format(
  //         new Date(checkInInfo?.last_check_in_at),
  //         'dd-MM-yy',
  //       )
  //       const currentDate = format(new Date(), 'dd-MM-yy')
  //       if (lastTimeCheckIn !== currentDate) {
  //         result = false
  //       }
  //     } else if (checkInInfo?.continuous_days === 0) {
  //       result = false
  //     }
  //   }
  //   return result
  // }, [checkInInfo, isLoading, activeAccount])

  return (
    <Container className="px-0" center={<AccountHeader />}>
      <div className="flex-grow">{renderContent()}</div>
      {/* {DailyReward(open, setOpen, checkInInfo, checked, refetch)} */}
      {/* {NotificationClaim(
        openNotification,
        setOpenNotification,
        point,
        setCheckedWL,
      )}
      {NotificationGrab(
        openNotificationGrab,
        setOpenNotificationGrab,
        whiteListInfo as WhiteListInfo,
        point,
        setCheckedWL,
      )} */}

      <Tabbar className="fixed bottom-0 pb-5 w-full bg-[#4B96DC] rounded-t-2xl z-50">
        {tabs.map(({ id, text, Icon }) =>
          id !== Tabs.Meme ? (
            <Tabbar.Item
              className="p-0 m-0"
              key={id}
              onClick={() => handleChangeTab(id)}
            >
              <div
                className={`h-10 px-8 gap-2 ${
                  id === currentTab
                    ? 'bg-[#C7E7FF] text-[#003F60]'
                    : 'bg-[#4B96DC] text-white'
                } rounded-xl flex items-center justify-center`}
              >
                <Icon isFocused={id === currentTab} />
                <span className="text-xs font-[600]">{text}</span>
              </div>
            </Tabbar.Item>
          ) : (
            <div
              key={id}
              className="relative w-[80px] h-[80px]"
              onClick={() => handleChangeTab(id)}
              onKeyDown={() => {}}
            >
              <img
                src={BearTabIcon}
                alt="rocket button"
                className="w-[80px] h-[80px] absolute top-[-15px] left-0"
              />
            </div>
          ),
        )}
      </Tabbar>
    </Container>
  )
}

// const DailyReward = (
//   open: boolean,
//   setOpen: (value: boolean) => void,
//   checkInInfo: checkInData,
//   checked: boolean,
//   refetch: () => void,
// ) => {
//   const [rankDay, setRankDay] = useState<number>(1)
//   const queryClient = useQueryClient()

//   useEffect(() => {
//     if (checkInInfo) {
//       if (checkInInfo?.continuous_days) {
//         if (checkInInfo?.last_check_in_at) {
//           const lastTimeCheckIn = format(
//             new Date(checkInInfo?.last_check_in_at),
//             'dd-MM-yy',
//           )
//           const now = new Date()
//           const subDay = now.getTime() - 24 * 60 * 60 * 1000
//           if (lastTimeCheckIn === format(subDay, 'dd-MM-yy')) {
//             setRankDay(checkInInfo.continuous_days + 1)
//           }
//         } else {
//           setRankDay(checkInInfo.continuous_days + 1)
//         }
//       }
//     }
//   }, [checkInInfo])

//   const arrayLever = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]

//   const completeMutation = useMutation({
//     mutationFn: checkIn,
//     onSuccess: () => {
//       toast.success('Check in success')
//       queryClient.invalidateQueries({
//         queryKey: ['my-point'],
//       })
//     },
//     onError: (error) => {
//       toast.error(`Unable to check in: ${error.message}`)
//     },
//   })
//   const handleCheckIn = async () => {
//     completeMutation.mutate()
//     await new Promise((resolve) => setTimeout(resolve, 1000))
//     refetch()
//     setOpen(false)
//   }
//   return (
//     !checked && (
//       <DailyRewardModal
//         open={open}
//         onClose={() => setOpen(false)}
//         heightPercent={0.67}
//         checkIn={handleCheckIn}
//         checked={checked}
//       >
//         <div className="flex flex-wrap gap-[5px] items-start justify-center">
//           {arrayLever.map((element) => {
//             const classContain =
//               'bg-[#1B5A88] h-[80px] w-[18%] rounded-[12px] border-solid flex items-center justify-around flex-col relative'
//             const borderColor =
//               element === (checked ? rankDay - 1 : rankDay)
//                 ? `${classContain} border-[#FFDE5E] border-[2px]`
//                 : `${classContain} border-[#2B7AB4] border-[1.5px]`
//             return (
//               <div key={element} className={borderColor}>
//                 {rankDay > element && (
//                   <div className="absolute w-[100%] h-[100%] bg-[#00000080] rounded-[12px]" />
//                 )}
//                 <p className="text-[10px] text-[#AFDDFF] font-[400] leading-[12px] font-[DM Sans]">
//                   Day {element}
//                 </p>
//                 <img
//                   src={rankDay > element ? Checked : BeraIcon}
//                   alt="bera icon"
//                   className="w-[20px] h-[20px]"
//                 />
//                 <p className="text-[12px] text-[#FFDE5E] font-bold leading-[16px] font-[DM Sans]">
//                   {(100 * element).toLocaleString('en-US')}
//                 </p>
//               </div>
//             )
//           })}
//         </div>
//       </DailyRewardModal>
//     )
//   )
// }

// const NotificationClaim = (
//   open: boolean,
//   setOpen: (value: boolean) => void,
//   point: number,
//   setCheckedWL: (value: boolean) => void,
// ) => {
//   const navigate = useNavigate()
//   return (
//     <BottomSheetModal
//       open={open}
//       title="Notification"
//       onClose={() => {
//         setOpen(false)
//         setCheckedWL(true)
//       }}
//       heightPercent={0.75}
//     >
//       <div className="flex flex-col justify-between px-4 py-5 gap-4 h-full w-full mb-[5px]">
//         <div className="flex flex-col px-4 py-5 gap-4 w-full">
//           <div className="w-full flex items-center justify-center">
//             <img
//               src={NotificationImageModal}
//               alt="notification modal"
//               className="h-[64px] w-[64px]"
//             />
//           </div>
//           <div className="w-full flex flex-col items-center justify-center">
//             <p className="text-[20px] text-[#ffffff] font-bold">BeraBonds</p>
//             <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
//               The Only 3,333 Proof of BearCage Contributors
//             </p>
//           </div>
//           <div className="w-full flex items-center justify-center gap-[10px]">
//             <img src={BeraCoinImg} className="w-[24px] h-[24px]" alt="icon" />
//             <p className="text-[20px] text-[#FFDE5E] font-[700]">
//               {formatNumber(point, 0)}
//             </p>
//             <p className="text-[20px] text-[#FFFFFF] font-[700]">$BECA</p>
//           </div>
//           <div className="w-full flex items-center justify-center">
//             <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
//               Congratulations! You have proved to become BearCage loyalty users.
//             </p>
//           </div>
//         </div>
//         <div className="pb-[10px]">
//           <WalletButton
//             onPress={() => navigate({ pathname: '/claim' })}
//             className="w-[100%] h-[48px]"
//           >
//             <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
//               Claim BeraBonds GTD WL
//             </p>
//           </WalletButton>
//         </div>
//       </div>
//     </BottomSheetModal>
//   )
// }

// const NotificationGrab = (
//   open: boolean,
//   setOpen: (value: boolean) => void,
//   whiteListInfo: WhiteListInfo,
//   point: number,
//   setCheckedWL: (value: boolean) => void,
// ) => {
//   const redirect = () => {
//     bottomTabStore.send({
//       type: 'setCurrentTab',
//       tab: Tabs.Tasks,
//     })
//     setOpen(false)
//   }
//   return (
//     <BottomSheetModal
//       open={open}
//       title="Notification"
//       onClose={() => {
//         setOpen(false)
//         setCheckedWL(true)
//       }}
//       heightPercent={0.75}
//     >
//       <div className="flex flex-col justify-between px-4 py-5 gap-4 h-full w-full mb-[5px]">
//         <div className="flex flex-col px-4 py-5 gap-4 w-full">
//           <div className="w-full flex items-center justify-center">
//             <img
//               src={NotificationImageModal}
//               alt="notification modal"
//               className="h-[64px] w-[64px]"
//             />
//           </div>
//           <div className="w-full flex flex-col items-center justify-center">
//             <p className="text-[20px] text-[#ffffff] font-bold">BeraBonds</p>
//             <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
//               The Only 3,333 Proof of BearCage Contributors
//             </p>
//           </div>
//           <div>
//             <div className="w-full flex items-center justify-center gap-[10px]">
//               <img src={BeraCoinImg} className="w-[24px] h-[24px]" alt="icon" />
//               <p className="text-[20px] text-[#FFDE5E] font-[700]">
//                 {formatNumber(point, 0)}
//               </p>
//               <p className="text-[20px] text-[#FFFFFF] font-[700]">$BECA</p>
//             </div>
//             <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
//               Need{' '}
//               {formatNumber(
//                 Number(whiteListInfo?.requirementPoint) - Number(point),
//                 0,
//               )}{' '}
//               more
//             </p>
//           </div>
//           <div className="w-full flex items-center justify-center">
//             <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
//               Grab more $BECA to be eligible for the BeraBonds GTD WL.
//             </p>
//           </div>
//         </div>
//         <div className="pb-[10px]">
//           <WalletButton onPress={redirect} className="w-[100%] h-[48px]">
//             <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
//               Grab more $BECA{' '}
//             </p>
//           </WalletButton>
//         </div>
//       </div>
//     </BottomSheetModal>
//   )
// }

// const StakeModal = (open: boolean, setOpen: (value: boolean) => void) => {
//   const navigate = useNavigate()
//   return (
//     <BottomSheetModal
//       open={open}
//       title="Notification"
//       onClose={() => {
//         setOpen(false)
//       }}
//       heightPercent={0.7}
//     >
//       <div className="flex flex-col justify-between px-4 py-5 gap-4 h-full w-full mb-[5px]">
//         <div className="flex flex-col py-5 gap-4 w-full">
//           <div className="w-full flex flex-col items-center justify-center">
//             <p className="text-[20px] text-[#ffffff] font-bold">BeraBonds</p>
//             <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
//               The Only 3,333 Proof of BearCage Contributors
//             </p>
//           </div>
//           <div className="w-full flex items-center justify-center">
//             <img
//               src={ClaimGif}
//               alt="claim gif"
//               className="h-[160px] w-[160px] rounded-[16px]"
//             />
//           </div>
//           <p className="text-[14px] text-[#ffffffa0] font-[400] text-center">
//             Stake BeraBonds, record contribution and get rewards.
//           </p>
//         </div>
//         <div className="pb-[10px]">
//           <WalletButton
//             onPress={() => navigate({ pathname: '/stake' })}
//             className="w-[100%] h-[48px]"
//           >
//             <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
//               Stake Now
//             </p>
//           </WalletButton>
//         </div>
//       </div>
//     </BottomSheetModal>
//   )
// }

export { BottomTab }
