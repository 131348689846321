import { useQuery } from '@tanstack/react-query'
import type { CandlestickData } from 'lightweight-charts'
import request from '../config/request'

const REFETCH_INTERVAL = 15 * 1000

export type PriceData = {
  currentPrice: number
  change: number
  changePercentage: number
}

type ChartData = {
  priceChart: CandlestickData[]
  priceData: PriceData
}

async function getChartData({
  tokenAddress,
  interval,
}: { tokenAddress: string; interval: string }) {
  const res = await request.get<ChartData>(`/price/candle/${tokenAddress}`, {
    params: {
      interval,
    },
  })

  return res.data
}

export const useChartData = ({
  tokenAddress,
  interval,
}: { tokenAddress: string; interval: string }) => {
  const { data, ...res } = useQuery({
    queryKey: ['chart-data-token', tokenAddress, interval],
    queryFn: () => getChartData({ tokenAddress, interval }),
    refetchInterval: REFETCH_INTERVAL,
  })
  return { data: data ?? null, ...res }
}
