import BGImage from '@/assets/Images/check-rank-page-image.png'
import BearCageSvg from '@/assets/svg/bearcage.svg'
import RankingWalletSvg from '@/assets/svg/ranking-wallet.svg'
import type { RankingWallet } from '@/libs/types'
import { format } from 'date-fns'

interface RankImageTemplateProps {
  rankWallet: RankingWallet
  address: string
}

export const RankImageTemplate = ({ rankWallet }: RankImageTemplateProps) => {
  return (
    <div
      className="fixed top-[-9999px] left-0 pointer-events-none"
      style={{ zIndex: -1 }}
    >
      <div
        className="w-[450px] h-[250px] relative flex flex-col items-start justify-center gap-[20px] p-[20px]"
        id="rank-image-template"
      >
        <img
          src={BGImage}
          alt="bg info"
          className="w-[450px] h-[250px] absolute top-0 left-0"
        />
        <div className="flex z-10 gap-2 justify-center items-center">
          <img src={BearCageSvg} className="w-8 h-8" alt="bearcage" />
          <p className="text-[16px] text-[#ffffff] font-bold">Bear Cage</p>
        </div>
        <div className="flex flex-col items-start justify-start z-10">
          <p className="text-[12px] text-[#ffffff] font-[400]">Ranking No.</p>
          <p className="text-[32px] text-[#28C17B] font-[600] leading-none">
            # {rankWallet?.widget?.data?.stats[0]?.value ?? 0}
          </p>
        </div>
        <div className="flex flex-col items-start justify-start z-10">
          <p className="text-[12px] text-[#ffffff] font-[400]">You're in top</p>
          <p className="text-[32px] text-[#28C17B] font-[600] leading-none">
            {rankWallet?.walletPerformance?.topPercent.toFixed(2) ?? 0}%
          </p>
        </div>
        <div className="flex z-10 gap-2 justify-between items-center w-full">
          <div className="flex gap-2 items-center">
            <img
              src={RankingWalletSvg}
              className="w-4 h-4"
              alt="ranking-wallet"
            />
            <p className="text-[12px] text-[#ffffff] font-bold">
              BearCage Wallet
            </p>
          </div>
          <div className="flex gap-2 items-center">
            <p className="text-[12px] text-[#ffffff] font-[400]">
              Recorded at: {format(new Date(), 'HH:mm dd/MM/yyyy')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
