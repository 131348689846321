import type { PriceData } from '@/libs/hooks'
import type { MemeToken } from '@/libs/types'
import {} from '@/libs/utils/number'
import { format } from 'date-fns'
import {
  type CandlestickData,
  CrosshairMode,
  type IChartApi,
  LineStyle,
  createChart,
} from 'lightweight-charts'
import type React from 'react'
import { useEffect, useRef } from 'react'

type ChartComponentProps = {
  data: CandlestickData[]
  isFetchingData: boolean
  priceData: PriceData
  tokenSelected: MemeToken
}

export const ChartComponent: React.FC<ChartComponentProps> = ({
  data,
  isFetchingData,
  priceData,
  tokenSelected,
}) => {
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<IChartApi | null>(null)

  useEffect(() => {
    if (chartContainerRef.current && !isFetchingData) {
      chartRef.current = createChart(chartContainerRef.current, {
        localization: {
          // priceFormatter: (price: number) => {
          //   return price.toFixed(10)
          // },
          timeFormatter: (businessDayOrTimestamp: number) => {
            return format(
              new Date(businessDayOrTimestamp * 1000),
              'yyyy-MM-dd HH:mm',
            )
          },
        },

        width: chartContainerRef.current.clientWidth,
        height: chartContainerRef.current.clientHeight,
        layout: {
          background: { color: 'transparent' },
          textColor: '#DDD',
        },
        timeScale: {
          borderColor: '#fff',
          barSpacing: 10,
          tickMarkFormatter: (time: number) => {
            return format(new Date(time * 1000), 'MMM dd HH:mm')
          },
        },
        rightPriceScale: {
          borderColor: '#fff',
          visible: true,
        },
        grid: {
          vertLines: { color: '#f6f3f322', style: LineStyle.Solid },
          horzLines: { color: '#f6f3f322', style: LineStyle.Solid },
        },
        crosshair: {
          // Change mode from default 'magnet' to 'normal'.
          // Allows the crosshair to move freely without snapping to datapoints
          mode: CrosshairMode.Normal,

          // Vertical crosshair line (showing Date in Label)
          vertLine: {
            width: 1,
            color: '#C3BCDB44',
            style: LineStyle.Solid,
            // labelBackgroundColor: '#9B7DFF',
          },

          // Horizontal crosshair line (showing Price in Label)
          horzLine: {
            color: '#DDD',
            // labelBackgroundColor: '#9B7DFF',
          },
        },
      })

      const candleStickData = data
      chartRef.current.timeScale().scrollToRealTime()
      chartRef.current.priceScale('right').applyOptions({
        scaleMargins: {
          top: 0.1,
          bottom: 0.1,
        },
      })
      const mainSeries = chartRef.current.addCandlestickSeries({
        upColor: '#4bffb5',
        downColor: '#ff4976',
        borderDownColor: '#ff4976',
        borderUpColor: '#4bffb5',
        wickDownColor: '#ff4976',
        wickUpColor: '#4bffb5',
        priceFormat: {
          type: 'price',
          minMove: 1e-10,
          precision: 10,
        },
        // borderVisible: false,
      })
      mainSeries.setData(candleStickData)

      const handleResize = () => {
        if (chartRef.current && chartContainerRef.current) {
          chartRef.current.applyOptions({
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
          })
        }
      }

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
        if (chartRef.current) {
          chartRef.current.remove()
        }
      }
    }
  }, [data, isFetchingData])

  const generateColor = (change: number) => {
    if (change > 0) {
      return 'text-[#4bffb5]'
    }
    return 'text-[#ff4976]'
  }

  return isFetchingData ? (
    <div className="w-full h-full flex-1 flex items-center justify-center">
      <Loading />
    </div>
  ) : (
    <div ref={chartContainerRef} className="w-full h-full flex-1 break-words">
      <div className="flex flex-wrap items-center gap-2">
        <p>{tokenSelected.symbol}</p>
        <p
          className={`text-sm ${generateColor(priceData?.change)}`}
        >{`${Number(priceData?.currentPrice).toFixed(10)}`}</p>
        <p
          className={`text-sm ${generateColor(priceData?.change)}`}
        >{`${Number(priceData?.change).toFixed(10)} (${Number(priceData?.changePercentage).toFixed(2)}%)`}</p>
      </div>
    </div>
  )
}

export const Loading: React.FC = () => {
  return (
    <div
      className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
      role="status"
    >
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        Loading...
      </span>
    </div>
  )
}
