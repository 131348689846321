import type { EmblaOptionsType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import type React from 'react'
import { Text } from 'react-aria-components'
import { twMerge } from 'tailwind-merge'

type SlideType = {
  id: number
  title: string
  description: string
  imageUrl: string
  className?: string
  btnComponent?: React.ReactNode
  balance: number | string
  logo: string
  customBalance?: React.ReactNode
  customLogoStyle?: string
  backgroundColor?: string
  customIconTop?: string
}

type PropType = {
  slides: SlideType[]
  options?: EmblaOptionsType
}

const EmblaCarousel: React.FC<PropType> = (props) => {
  const { slides, options } = props
  const [emblaRef] = useEmblaCarousel(options)

  return (
    <section className="embla" id="embla-carousel">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((item) => (
            <div className="embla__slide" key={item.id}>
              <div className="embla__slide__number">
                <div
                  className={` flex flex-col ${item?.backgroundColor ? item?.backgroundColor : 'bg-yellow-800 gradient_border_refill'} w-full h-[80%] rounded-2xl px-4 justify-center `}
                >
                  <div className="flex flex-col">
                    {item?.customIconTop && <img src={item.customIconTop} alt="point" className="w-[auto] h-[16px] max-w-[53px]" />}
                    <Text className="text-rgba font-bold text-xl">
                      {item.title}
                    </Text>
                    <Text className="text-rgba font-normal text-xs max-w-[60%] whitespace-pre-line">
                      {item.description}
                    </Text>
                  </div>
                  {item?.customBalance ? (
                    item?.customBalance
                  ) : (
                    <div className="flex mt-1 items-center">
                      <img src={item.logo} alt="point" className="w-6 h-6" />
                      <Text className="text-rgba font-bold text-xl ml-2">
                        {item.balance.toLocaleString('en-US')}
                      </Text>
                    </div>
                  )}
                </div>
                <img
                  src={item.imageUrl}
                  alt="bera"
                  className={twMerge(
                    'absolute top-2 right-[14px] w-[146px]',
                    item.className,
                  )}
                />
                {item.btnComponent}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export { EmblaCarousel }
