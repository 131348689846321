
import Back from '@/assets/svg/back-icon.svg'
import GraduatedToken from '@/assets/svg/stack-total-graduated.svg'
import TotalToken from '@/assets/svg/stack-total-token.svg'
import SwitchIcon from '@/assets/svg/switch.svg'
import BeraCoinImg from '@/assets/webps/bera-coin.webp'
import { WalletButton } from '@/components/Button'
import { AccountHeader, Container } from '@/components/Layout'
import { BottomSheetModal } from '@/components/Modal/BottomSheetModal'
import { useBeraBondSnapshot } from '@/libs/hooks'
import { useRanking } from '@/libs/hooks/useRanking'
import type { Nft, } from '@/libs/types'
import { BERA_LOGO, tierInfo } from '@/libs/utils/constans'
import { publicClient } from '@/libs/utils/wallet/meme'
import { unStakeNft } from '@/libs/utils/wallet/stake'
import { stakeStore } from '@/store/StakeStore'
import { walletStore } from '@/store/WalletStore'
import { useUtils } from '@telegram-apps/sdk-react'
import { useSelector } from '@xstate/store/react'
import { useMemo, useState } from 'react'
import {} from 'react-aria-components'
import { toast } from 'sonner'
import { privateKeyToAccount } from 'viem/accounts'
import { useWriteContract } from 'wagmi'

const DetailStake = () => {
  const utils = useUtils()
  const { selectedNft } = useSelector(stakeStore, (state) => state.context)
  const { activeAccount } = useSelector(walletStore, (state) => state.context)
  const signer = privateKeyToAccount(activeAccount?.privateKey as `0x${string}`)
  const [openInfo, setOpenInfo] = useState<boolean>(false)
  const { writeContractAsync } = useWriteContract()
  const [isLoading, setIsLoading] = useState(false)
  const beraBondSnapShot = useBeraBondSnapshot(selectedNft?.token_id as number)
  console.log({ beraBondSnapShot })

  const ranking = useRanking()

  const infoNft = useMemo(() => {
    return [
      {
        icon: BeraCoinImg,
        name: 'Total $BECA',
        recorded: beraBondSnapShot?.recordedSnapshot.totalBeca,
        preview: beraBondSnapShot?.previewChangedSnapshot.totalBeca,
      },
      {
        icon: BERA_LOGO,
        name: 'Total $BERA Volume',
        recorded: beraBondSnapShot?.recordedSnapshot.totalBeraVolume,
        preview: beraBondSnapShot?.previewChangedSnapshot.totalBeraVolume,
      },
      {
        icon: SwitchIcon,
        name: 'Total Transactions',
        recorded: beraBondSnapShot?.recordedSnapshot.totalTransactions,
        preview: beraBondSnapShot?.previewChangedSnapshot.totalTransactions,
      },
      {
        icon: TotalToken,
        name: 'Total Tokens Created',
        recorded: beraBondSnapShot?.recordedSnapshot.totalTokenCreated,
        preview: beraBondSnapShot?.previewChangedSnapshot.totalTokenCreated,
      },
      {
        icon: GraduatedToken,
        name: 'Total Graduated Tokens',
        recorded: beraBondSnapShot?.recordedSnapshot.totalCompletedToken,
        preview: beraBondSnapShot?.previewChangedSnapshot.totalCompletedToken,
      },
    ]
  }, [beraBondSnapShot])

  const [open, setOpen] = useState<boolean>(false)

  const unstake = async () => {
    setIsLoading(true)
    try {
      const request = await unStakeNft({
        nftId: Number(selectedNft?.token_id),
        signer,
      })
      const txHash = await writeContractAsync({
        ...request,
        account: signer,
      })
      const receipt = await publicClient.waitForTransactionReceipt({
        hash: txHash as `0x${string}`,
        confirmations: 1,
      })
      if (receipt.status === 'success') {
        toast.success('Unstake Successfully!')
        stakeStore.send({
          type: 'setUnstakedNft',
          nft: selectedNft as Nft,
        })
        stakeStore.send({
          type: 'setStakeStep',
          step: 'list',
        })
      }
    } catch (error) {
      console.log({ error })
      toast.error('Unstake Failed!')
    } finally {
      setIsLoading(false)
    }
  }

  const currentRank = useMemo(() => {
    let result = tierInfo[5]
    const checkRank = tierInfo.find((element) => element.totalVolume <= (infoNft[1].recorded as number) && element.totalTokenCreated <= (infoNft[3].recorded as number) && element.totalGraduatedToken <= (infoNft[4].recorded as number))
    if(checkRank) {
      result = checkRank
    }
    return result
  }, [infoNft])

  const currentVolumeRank = useMemo(() => {
    let result = tierInfo[5]
    const checkRank = tierInfo.find((element) => element.totalVolume <= (infoNft[1].recorded as number))
    if(checkRank) {
      result = checkRank
    }
    return result
  }, [infoNft])

  const currentTokenCreatedRank = useMemo(() => {
    let result = tierInfo[5]
    const checkRank = tierInfo.find((element) => element.totalTokenCreated <= (infoNft[3].recorded as number))
    if(checkRank) {
      result = checkRank
    }
    return result
  }, [infoNft])

  const currentGraduatedTokenRank = useMemo(() => {
    let result = tierInfo[5]
    const checkRank = tierInfo.find((element) => element.totalGraduatedToken <= (infoNft[4].recorded as number))
    if(checkRank) {
      result = checkRank
    }
    return result
  }, [infoNft])

  return (
    <Container
      className="px-0 min-h-[100vh] overflow-auto max-h-[100vh]"
      center={<AccountHeader />}
    >
      <div className="w-full  flex flex-col items-center justify-between py-[30px] px-[20px] max-h-[90vh] overflow-auto">
        <div className="w-full flex flex-col gap-[20px] items-center justify-center">
          <div
            className="w-full rounded-[14px] min-h-[30px] flex items-center justify-start gap-[10px]"
            onClick={() =>
              stakeStore.send({
                type: 'setStakeStep',
                step: 'list',
              })
            }
            onKeyDown={() => {}}
          >
            <img src={Back} alt="social open" className="w-[24px] h-[24px]" />
            <p className="text-[#71C2FF] text-[16px] font-[400] flex items-center justify-center gap-[5px] h-full">
              Back
            </p>
          </div>
          <div className='flex flex-col items-center justify-center gap-[10px] w-full'>
            <div className='flex items-center justify-between w-full'>
              <div className='flex items-center justify-start gap-[10px]'>
                <img src={currentRank.icon} alt="rank" className='h-[32px] w-[25px]' />
                <p className='text-[14px] text-[#ffffff] font-[700]'>{currentRank.name}</p>
              </div>
              <p className='text-[14px] text-[#C3E6FF] font-[400] underline' onClick={() => setOpenInfo(true)} onKeyDown={() => {}}>What is tier?</p>
            </div>
            <div className='flex flex-col gap-[5px] items-center justify-center mb-[5px]'>
              <p className='text-[24px] text-[#ffffff] font-bold leading-[1]'>{currentRank.rankName}</p>
              {currentRank.name !== 'Tier 1' && <p className='text-[14px] font-bold info-total-top-rank'><span className='info-top-rank'>{`Top ${ranking ? ranking.rank : currentRank.top}`}</span>{`/${currentRank.top}`}</p>}
              {currentRank.name === 'Tier 1' && <p className='text-[14px] font-bold text-[#BBE3FF]'>Top 100 (Max)</p>}
            </div>
            <div className='flex items-stretch justify-center gap-[10px] w-full'>
              <div className='w-[calc(33.33%-10px)] flex flex-col items-center justify-center border-[1px] border-solid border-[#B3DFFF] rounded-[12px] py-[8px] px-[12px] gap-[10px] relative'>
                <img src={currentVolumeRank.border} alt="status task" className='w-[12px] h-[12px] absolute top-[-6px] left-[calc(50%-6px)]' />
                <p className='text-[10px] text-[#ABDCFF] font-bold'>BERA Volume</p>
                <p className='text-[12px] text-[#BADEF7] font-bold' style={{ wordBreak: 'break-word'}}>{infoNft[1]?.recorded ? (infoNft[1].recorded as number).toLocaleString('en-US') : 0}<span className='text-[#BADEF799]'>{`/${currentVolumeRank.totalVolume.toLocaleString('en-US')}`}</span></p>
              </div>
              <div className='w-[calc(33.33%-10px)] flex flex-col items-center justify-center border-[1px] border-solid border-[#B3DFFF] rounded-[12px] py-[8px] px-[12px] gap-[10px] relative'>
                <img src={currentTokenCreatedRank.border} alt="status task" className='w-[12px] h-[12px] absolute top-[-6px] left-[calc(50%-6px)]' />
                <p className='text-[10px] text-[#ABDCFF] font-bold'>Token Created</p>
                <p className='text-[12px] text-[#BADEF7] font-bold' style={{ wordBreak: 'break-word'}}>{infoNft[3]?.recorded ? (infoNft[3].recorded as number).toLocaleString('en-US') : 0}<span className='text-[#BADEF799]'>{`/${currentTokenCreatedRank.totalTokenCreated.toLocaleString('en-US')}`}</span></p>
              </div>
              <div className='w-[calc(33.33%-10px)] flex flex-col items-center justify-center border-[1px] border-solid border-[#B3DFFF] rounded-[12px] py-[8px] gap-[10px] relative'>
                <img src={currentGraduatedTokenRank.border} alt="status task" className='w-[12px] h-[12px] absolute top-[-6px] left-[calc(50%-6px)]' />
                <p className='text-[10px] text-[#ABDCFF] font-bold text-center'>Graduated Token</p>
                <p className='text-[12px] text-[#BADEF7] font-bold' style={{ wordBreak: 'break-word'}}>{infoNft[4]?.recorded ? (infoNft[4].recorded as number).toLocaleString('en-US') : 0}<span className='text-[#BADEF799]'>{`/${currentGraduatedTokenRank.totalGraduatedToken.toLocaleString('en-US')}`}</span></p>
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-center rounded-t-[36px] rounded-b-[18px]">
            <div className="rounded-t-[36px] rounded-b-[18px] p-[1.5px] w-[100%] bg-[#52A7E73D] relative">
              <img
                src={selectedNft?.image_url}
                alt={`NFT ${selectedNft?.name}`}
                className="w-full h-[auto] rounded-[18px] border-[1.5px] border-solid border-[#3892CF]"
              />
              <div className="p-[10px] text-left">
                <p className="text-[#FFFFFF] text-[14px] font-[700] text-center">
                  {selectedNft?.name}
                </p>
              </div>
            </div>
          </div>
          <div className="bg-[#52A7E73D] border-[1.5px] border-solid border-[#3892CF] rounded-[16px] w-full py-[12px] px-[16px] flex flex-col">
            {infoNft.map((element, index: number) => {
              return (
                <div
                  key={element.name}
                  className={`${index === infoNft.length - 1 ? 'border-b-[0px]' : 'border-b-[1px]'} border-solid border-[#52A7E733] min-h-[36px] flex items-center justify-between w-full mb-[12px]`}
                >
                  <div className="flex gap-[10px]">
                    <img
                      src={element.icon}
                      alt={element.name}
                      className="w-[24px] h-[24px]"
                    />
                    <p className="text-[14px] text-[#ffffff] font-[400]">
                      {element.name}
                    </p>
                  </div>
                  <div className="flex gap-[10px]">
                    <p className="text-[14px] text-[#FAC808] font-[600] text-end]">
                      {Number(element.preview).toLocaleString()}
                    </p>
                    <p className="text-[14px] text-[#ffffff] font-[600] text-end min-w-[60px]">
                      {element.recorded?.toLocaleString()}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="flex gap-[10px] items-center justify-center mt-[-10px]">
            <div className="flex gap-[5px] items-center justify-center">
              <div className="w-[8px] h-[8px] bg-[#FAC808]" />
              <p className="text-[12px] text-[#FFFFFFA3] font-[500] text-end">
                Unrecorded
              </p>
            </div>
            <div className="flex gap-[5px] items-center justify-center">
              <div className="w-[8px] h-[8px] bg-[#ffffff]" />
              <p className="text-[12px] text-[#FFFFFFA3] font-[500] text-end">
                Recorded
              </p>
            </div>
          </div>
          <p className="text-[14px] text-[#FFFFFF] font-[700] text-left w-full">
            Notice:
          </p>
          <p className="text-[14px] text-[#FFFFFFA3] font-[400] text-left">
            You can unstake and stake anytime. For each time you stake and
            unstake, your information will be recorded in your BeraBonds
            metadata. Once it is recorded, you can not redo the action.
          </p>
          <p className="text-[14px] text-[#FFFFFFA3] font-[400] text-left">
            Each telegram user can stake 1 BeraBonds at a time Once you unstake
            1 BeraBonds, you can stake another one then.
          </p>
          <div className="w-[100%] h-[48px]">
            <WalletButton
              onPress={() => setOpen(true)}
              className="w-[100%] h-[48px]"
            >
              <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
                Unstake Now
              </p>
            </WalletButton>
          </div>
        </div>
        <BottomSheetModal
          open={open}
          title="Unstake"
          onClose={() => setOpen(false)}
          heightPercent={0.95}
        >
          <div className="flex flex-col justify-between px-4 py-5 gap-4 h-full w-full mb-[5px]">
            <div className="flex flex-col px-1 py-2 gap-4 w-full">
              <div className="w-full flex flex-col items-center justify-center">
                <p className="text-[20px] text-[#ffffff] font-bold text-center">
                  Are you sure to unstake your BeraBonds?
                </p>
                <p className="text-[14px] text-[#ffffffa3] font-[400] text-center">
                  This is your BeraBonds database which is going to be recorded.
                  Remember once it is recorded, you can not redo the action.
                </p>
              </div>
              <div className="bg-[#52A7E73D] h-[76px] w-full rounded-[16px] p-[4px] flex gap-[10px] items-center justify-start">
                <img
                  src={selectedNft?.image_url}
                  className="h-[68px] w-[68px]"
                  alt="nft detail"
                />
                <div className="flex flex-col gap-[2px] items-start">
                  <p className="text-[14px] font-[700] text-[#ffffff]">
                    {selectedNft?.name}
                  </p>
                  <p className="text-[12px] font-[400] text-[#ffffffa3]">
                    {activeAccount?.name}
                  </p>
                </div>
              </div>
              <div className="bg-[#52A7E73D] border-[1.5px] border-solid border-[#3892CF] rounded-[16px] w-full py-[12px] px-[16px] flex flex-col">
                {infoNft.map((element, index: number) => {
                  return (
                    <div
                      key={element.name}
                      className={`${index === infoNft.length - 1 ? 'border-b-[0px]' : 'border-b-[1px]'} border-solid border-[#52A7E733] min-h-[36px] flex items-center justify-between w-full mb-[12px]`}
                    >
                      <div className="flex gap-[10px]">
                        <img
                          src={element.icon}
                          alt={element.name}
                          className="w-[24px] h-[24px]"
                        />
                        <p className="text-[14px] text-[#ffffff] font-[400]">
                          {element.name}
                        </p>
                      </div>
                      <p className="text-[14px] text-[#ffffff] font-[600]">
                        {element.preview?.toLocaleString()}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="pb-[10px] flex items-center justify-between gap-[20px]">
              <WalletButton
                onPress={() => setOpen(false)}
                color="blue-800"
                className="w-[calc(50%-20px)] h-[48px]"
              >
                <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
                  Cancel
                </p>
              </WalletButton>
              <WalletButton
                onPress={unstake}
                isDisabled={isLoading}
                className="w-[calc(50%-20px)] h-[48px] disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
                  {isLoading ? 'Unstaking...' : 'Unstake'}
                </p>
              </WalletButton>
            </div>
          </div>
        </BottomSheetModal>
        <BottomSheetModal
          open={openInfo}
          title="Tier Info"
          onClose={() => {
            setOpenInfo(false)
          }}
          heightPercent={0.65}
        >
          <div className="flex flex-col justify-between px-4 py-5 gap-4 h-full w-full mb-[5px]">
            <div className="flex flex-col py-0 gap-4 w-full">
              <div className='flex items-center justify-between w-full'>
                <p className='text-[14px] text-[#ffffffa0] font-[400]'>Higher tier means higher rewards!</p>
                <p className='text-[14px] text-[#ffffffa0] font-[400] underline cursor-pointer' onClick={() => utils.openLink('https://bearcage.gitbook.io/overall')} onKeyDown={() => {}}>See details</p>
              </div>
              {tierInfo.map((element) => {
                return (
                  <div className='flex items-center justify-between w-full' key={element.name}>
                    <div className='flex items-center justify-start gap-[10px]'>
                      <img src={element.icon} alt="rank" className='h-[32px] w-[25px]' />
                      <p className='text-[14px] text-[#ffffff] font-[700]'>{element.name}</p>
                    </div>
                    <p className='info-modal-top'>Top {element.top}</p>
                  </div>
                )
              })}
            </div>
            <div className="pb-[10px]">
              <WalletButton
                onPress={() => setOpenInfo(false)}
                className="w-[100%] h-[48px]"
              >
                <p className="text-[16px] text-[#FFFFFF] font-[700] leading-[24px] font-[DM Sans]">
                  Got it
                </p>
              </WalletButton>
            </div>
          </div>
        </BottomSheetModal>
      </div>
    </Container>
  )
}

export { DetailStake }
