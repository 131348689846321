import { toPng } from 'html-to-image'
import { v4 as uuidv4 } from 'uuid'
import request from '../config/request'

// Thêm hàm utility để chuyển đổi dataURL thành Blob
async function dataUrlToBlob(dataUrl: string): Promise<Blob> {
  return fetch(dataUrl).then((res) => res.blob())
}

async function getPresignedUrl(key: string): Promise<string> {
  const res = await request.get<string>(`/upload/presigned-url?key=${key}`)
  return res.data
}

async function uploadToPresignedUrl(url: string, blob: Blob): Promise<void> {
  const response = await fetch(url, {
    method: 'PUT',
    body: blob,
    headers: {
      'Content-Type': 'image/jpeg',
    },
  })

  if (!response.ok) {
    throw new Error('Upload failed')
  }
}

async function generateImage(): Promise<{ url: string }> {
  try {
    const element = document.getElementById('rank-image-template')
    if (!element) throw new Error('Element not found')

    await toPng(element, {
      quality: 1.0,
      width: 450,
      height: 250,
      // pixelRatio: 3,
      cacheBust: true,
    })
    await toPng(element, {
      quality: 1.0,
      width: 450,
      height: 250,
      // pixelRatio: 3,
      cacheBust: true,
    })
    const dataUrl = await toPng(element, {
      quality: 1.0,
      width: 450,
      height: 250,
      // pixelRatio: 3,
      cacheBust: true,
    })
    const blob = await dataUrlToBlob(dataUrl)

    const key = `images/${uuidv4()}.jpeg`
    const presignedUrl = await getPresignedUrl(key)

    await uploadToPresignedUrl(presignedUrl, blob)

    const publicUrl = `https://${import.meta.env.VITE_AWS_S3_BUCKET}.s3.${import.meta.env.VITE_AWS_REGION}.amazonaws.com/${key}`

    return { url: publicUrl }
  } catch (error) {
    console.error('Failed to generate image:', error)
    throw error
  }
}

export const useGenerateImage = () => {
  return {
    generateImage,
  }
}
