import { createStore } from '@xstate/store'

const notificationStore = createStore(
  {
    showRankingCheck: true,
  },
  {
    setShowRankingCheck: (_, { show }: { show: boolean }) => ({
      showRankingCheck: show,
    }),
  },
)

export { notificationStore }
