import { useQuery } from '@tanstack/react-query'
import request from '../config/request'
import type { MemeToken } from '../types'

async function getDetailMeme(contractAddress: string) {
  const res = await request.get<MemeToken>(`meme-coin/${contractAddress}`)
  return res.data
}

export function useDetailMeme(contractAddress: string) {
  const { data, ...res } = useQuery({
    queryKey: ['get-detail-meme', contractAddress],
    queryFn: () => getDetailMeme(contractAddress),
    enabled: !!contractAddress,
  })

  return { memeInfo: data, ...res }
}
